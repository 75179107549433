import React, { lazy } from 'react'
import { Router, Redirect, Route, Switch } from 'react-router-dom'
import BigNumber from 'bignumber.js'
import useEagerConnect from 'hooks/useEagerConnect'
import { usePollBlockNumber } from 'state/block/hooks'
import { DatePickerPortal } from 'components/DatePicker'
import Menu from './components/Menu'
import Footer from './components/Footer'
import SuspenseWithChunkError from './components/SuspenseWithChunkError'
import { ToastListener } from './contexts/ToastsContext'
import PageLoader from './components/Loader/PageLoader'
import history from './routerHistory'

// Route-based code splitting
const NotFound = lazy(() => import('./views/NotFound'))
const Pool = lazy(() => import('./views/Pool'))
const Profile = lazy(() => import('./views/Profile'))
const Jackpot = lazy(() => import('./views/Jackpot'))
const Maintenance = lazy(() => import('./views/Maintenance'))

// This config is required for number formatting
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

const App: React.FC = () => {
  usePollBlockNumber()
  useEagerConnect()

  const isMaintenance = JSON.parse(process.env.REACT_APP_MAINTENANCE_MODE)

  return (
    <Router history={history}>
      <div className="main-page-wrapper">
        <div className="html-top-content">
          <Menu />
          <SuspenseWithChunkError fallback={<PageLoader />}>
            {isMaintenance ?
              <Switch>
                <Route exact strict path="/maintenance" component={Maintenance} />
                <Redirect exact from="/" to="/maintenance" />
                <Route component={Maintenance} />
              </Switch>
              :
              <Switch>
                <Route exact strict path="/farm" component={Pool} />
                <Route exact strict path="/aggregator" component={Jackpot} />
                <Route exact strict path="/profile" component={Profile} />
                <Route exact strict path="/profile/:refCode" component={Profile} />

                {/* Redirect */}
                <Redirect exact from="/" to="/farm" />

                {/* 404 */}
                <Route component={NotFound} />
              </Switch>
            }
          </SuspenseWithChunkError>
          <ToastListener />
          <DatePickerPortal />
        </div>
      </div>
      <Footer />
    </Router>
  )
}

export default React.memo(App)
