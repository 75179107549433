import { ChainId } from '@pancakeswap/sdk'
import BigNumber from 'bignumber.js/bignumber'
import { BIG_TEN } from 'utils/bigNumber'

BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

export const BSC_BLOCK_TIME = 3

export const BASE_ETH_SCAN_URLS = {
  [ChainId.MAINNET]: 'https://etherscan.io/',
  [ChainId.TESTNET]: 'https://ropsten.etherscan.io/',
}

export const ETH_SCAN_URLS = {
  1: 'https://etherscan.io/',
  3: 'https://ropsten.etherscan.io/',
  4: 'https://rinkeby.etherscan.io/'
}

export const BASE_URL = 'https://pancakeswap.finance'
export const BASE_ETH_SCAN_URL = BASE_ETH_SCAN_URLS[ChainId.MAINNET]
export const DEFAULT_TOKEN_DECIMAL = BIG_TEN.pow(18)
export const DEFAULT_GAS_LIMIT = 350000
export const DEFAULT_GAS_PRICE = 5
