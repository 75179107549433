export default {
  stakingContr: {
    1: '0x31E329B6c9Eabe77ddB08C61a3c0A95e8c1137aA',
    3: '',
    4: '0x524a04Bcd4870aD85F2bd271c8695032E197b355'
  },
  jackpotContr: {
    1: '0xFb503C29ABDa6e854Ba98EBA3007cdC86D8aa690',
    3: '',
    4: '0x2b1bB998Eb453d7918B5cE98eA8B3862F658E1B3'
  },
  jackpotContrV2: {
    1: '0xD2b46A100486D7aFBdC89735f7F30Dfc28888860',
    3: '',
    4: '0x4076708993A85bf2A24B67cfC16A7C29F83981Fc'
  },
  tubeFarmContr: {
    1: '0x5fe65B1172E148d1Ac4F44fFc4777c2D4731ee8f',
    3: '',
    4: '0xEc279De720A49615467306802A49aF3Cb5cb12C4'
  },
  treasuryContr: {
    1: '0x7E564A95C03D97a8074A24e90C1125A45e9aF5f7',
    3: '',
    4: '0xF3DbAe91e34e988d25A57b4ef5529EF0c41F4C5D'
  },
  multiCall: {
    1: '0x2a80799F88896E16f963284C7583f365CD645A1B',
    3: '',
    4: '0x66ab763FC0e25018BB6B693feFdE0AB31D67EB3c'
  },
}
