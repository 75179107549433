import { ethers } from 'ethers'
import { simpleRpcProvider } from 'utils/providers'

// Addresses
import {
  getStakingAddress,
  getMulticallAddress,
  getJackpotAddress,
  getTubeFarmAddress,
  getTreasuryAddress,
  getJackpotAddressV2
} from 'utils/addressHelpers'

// ABI
import bep20Abi from 'config/abi/erc20.json'
import erc721Abi from 'config/abi/erc721.json'
import MultiCallAbi from 'config/abi/Multicall.json'
import stakingAbi from 'config/abi/staking.json'
import jackpotAbi from 'config/abi/jackpot.json'
import jackpotV2Abi from 'config/abi/jackpotV2.json'
import tubFarmAbi from 'config/abi/tube-farm.json'
import treasuryAbi from 'config/abi/treasury.json'
import { abi as IUniswapV2PairABI } from '@uniswap/v2-core/build/IUniswapV2Pair.json'

const getContract = (abi: any, address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  const signerOrProvider = signer ?? simpleRpcProvider
  return new ethers.Contract(address, abi, signerOrProvider)
}

export const getStakingContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(stakingAbi, getStakingAddress(), signer)
}

export const getJackpotContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(jackpotAbi, getJackpotAddress(), signer)
}

export const getJackpotContractV2 = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(jackpotV2Abi, getJackpotAddressV2(), signer)
}

export const getTubeFarmContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(tubFarmAbi, getTubeFarmAddress(), signer)
}

export const getTubePairContract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(IUniswapV2PairABI, address, signer)
}

export const getTreasuryContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(treasuryAbi, getTreasuryAddress(), signer)
}

export const getBep20Contract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(bep20Abi, address, signer)
}

export const getErc721Contract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(erc721Abi, address, signer)
}

export const getMulticallContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(MultiCallAbi, getMulticallAddress(), signer)
}
