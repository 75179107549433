import BigNumber from 'bignumber.js'
import poolsConfig from 'config/constants/pool'
import multicall from 'utils/multicall'
import { getAddress, getTubeFarmAddress } from 'utils/addressHelpers'
import stakingABI from 'config/abi/staking.json'
import tubeFarmABI from 'config/abi/tube-farm.json'

export const fetchPoolsTotalStaking = async () => {
  const calls = poolsConfig.map((p) => ({
    address: getAddress(p.contractAddress),
    name: 'pools',
    params: [p.pId],
  }))

  const poolsTotalStaked = await multicall(stakingABI, calls)

  return [
    ...poolsConfig.map((p, index) => ({
      pId: p.pId,
      totalStaked: new BigNumber(poolsTotalStaked[index][1].toString()).toJSON()
    }))
  ]
}

export const fetchTubeFarmInfo = async () => {
  const calls = poolsConfig.map((p) => ({
    address: getTubeFarmAddress(),
    name: 'pools',
    params: [p.farmId],
  }))

  const farm = await multicall(tubeFarmABI, calls)

  return [
    ...poolsConfig.map((p, index) => ({
      pId: p.pId,
      farmInfo: farm[index].toString().split(','),
    }))
  ]
}
