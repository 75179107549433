import React, { useState } from 'react'
import styled from 'styled-components'
import { useWeb3React } from '@web3-react/core'
import ConnectWalletButton from 'components/ConnectWalletButton'
import AccountWalletButton from 'components/AccountWalletButton'
import { useTranslation } from 'contexts/Localization'
import { languageList } from 'config/localization/languages'
import LangSelector from '../LangSelector'

const StyledNavButton = styled.li`
  padding: 10px;
`

const Menu = () => {
  const { account } = useWeb3React()
  const { t, setLanguage, currentLanguage } = useTranslation()
  const [show, setShow] = useState(false)

  return (
    <>
      <header className="theme-main-menu color-white">
        <div className="container">
          <div className="menu-wrapper clearfix">
            <a href='/' className="logo"><img src="/images/logo2.png?v=2" alt="Logo" width="150" height="38" /></a>

            <ul className="right-widget celarfix">
              <li>
                <div id="polyglotLanguageSwitcher">
                  <LangSelector currentLang={currentLanguage.code} langs={languageList} setLang={setLanguage} />
                </div>
              </li>
              <li className="connect-button">
                {!account ? <ConnectWalletButton /> : <AccountWalletButton account={account} />}
              </li>
            </ul>

            <nav className="navbar navbar-expand-lg dark-bg" id="mega-menu-holder">
              <div className="container">
                <button className="navbar-toggler" type="button" onClick={() => setShow(!show)}>
                  <i className="fa fa-bars" aria-hidden="true" />
                </button>
                <div style={show ? { display: "block" } : { display: 'none' }} className="collapse navbar-collapse" id="navbarResponsive">
                  <ul className="navbar-nav">
                    <StyledNavButton style={show ? { display: "block" } : { display: 'none' }} className="nav-item">
                      {!account ? <ConnectWalletButton /> : <AccountWalletButton account={account} />}
                    </StyledNavButton>
                    <li className="nav-item">
                      <a className="nav-link js-scroll-trigger" href="/farm">{t('Farm')}</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link js-scroll-trigger" href="/aggregator">{t('Aggregator')}</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link js-scroll-trigger" href="/profile">{t('Profile')}</a>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </header>
    </>
  )
}

export default Menu
