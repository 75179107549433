import poolsConfig from 'config/constants/pool'
import erc20ABI from 'config/abi/erc20.json'
import multicall from 'utils/multicall'
import { getAddress, getJackpotAddress } from 'utils/addressHelpers'
import BigNumber from 'bignumber.js'
import stakingABI from 'config/abi/staking.json'
import jackpotABI from 'config/abi/jackpot.json'

export const fetchPoolsAllowance = async (account) => {
  const calls = poolsConfig.map((p) => ({
    address: getAddress(p.stakingToken.address),
    name: 'allowance',
    params: [account, getAddress(p.contractAddress)],
  }))

  const allowances = await multicall(erc20ABI, calls)

  return poolsConfig.reduce(
    (acc, pool, index) => ({ ...acc, [pool.pId]: new BigNumber(allowances[index].toString()).toJSON() }),
    {},
  )
}

export const fetchUserBalances = async (account) => {
  const calls = poolsConfig.map((p) => ({
    address: getAddress(p.stakingToken.address),
    name: 'balanceOf',
    params: [account],
  }))
  const tokenBalancesRaw = await multicall(erc20ABI, calls)
  return poolsConfig.reduce(
    (acc, pool, index) => ({ ...acc, [pool.pId]: new BigNumber(tokenBalancesRaw[index].toString()).toJSON() }),
    {},
  )
}

export const fetchUserStakeBalances = async (account) => {
  const calls = poolsConfig.map((p) => ({
    address: getAddress(p.contractAddress),
    name: 'getUserRewardByAddress',
    params: [p.pId, account],
  }))
  const userInfo = await multicall(stakingABI, calls)
  const stakedBalances = poolsConfig.reduce(
    (acc, pool, index) => ({
      ...acc,
      [pool.pId]: new BigNumber(userInfo[index][3].toString()).toJSON(),
    }),
    {},
  )

  return { ...stakedBalances }
}

export const fetchUserPendingRewards = async (account) => {
  const calls = poolsConfig.map((p) => ({
    address: getAddress(p.contractAddress),
    name: 'getUserRewardByAddress',
    params: [p.pId, account],
  }))
  const userInfo = await multicall(stakingABI, calls)
  const stakedBalances = poolsConfig.reduce(
    (acc, pool, index) => ({
      ...acc,
      [pool.pId]: new BigNumber(userInfo[index][1].toString()).toJSON(),
    }),
    {},
  )

  return { ...stakedBalances }
}

export const fetchIsUserJackpotJoined = async (account) => {
  const calls = poolsConfig.map((p) => ({
    address: getJackpotAddress(),
    name: 'getCurrentSessionJoined',
    params: [account],
  }))
  const userInfo = await multicall(jackpotABI, calls)
  const isJackpot = poolsConfig.reduce(
    (acc, pool, index) => ({
      ...acc,
      [pool.pId]: JSON.parse(userInfo[0].toString()),
    }),
    {},
  )

  return { ...isJackpot }
}
