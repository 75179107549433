import React from "react";
import styled from 'styled-components'
import { Text, Dropdown, Button, LanguageIcon, Language, ChevronDownIcon } from "@pancakeswap/uikit";

interface Props {
  currentLang: string;
  langs: Language[];
  setLang: (lang: Language) => void;
}

const LangSelector: React.FC<Props> = ({ currentLang, langs, setLang }) => (
  <Dropdown
    target={
      <Button variant="text" endIcon={<ChevronDownIcon className="white" width="24px" />}>
        <Text className="text-white">{currentLang?.toUpperCase()}</Text>
      </Button>
    }
  >
    {langs.map((lang) => (
      <Button
        key={lang.locale}
        onClick={() => setLang(lang)}
        className="lang-btn"
      >
        {lang.language}
      </Button>
    ))}
  </Dropdown>
);

export default React.memo(LangSelector, (prev, next) => prev.currentLang === next.currentLang);
