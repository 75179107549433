import { createSlice } from '@reduxjs/toolkit'
import poolsConfig from 'config/constants/pool'
import { PoolsState, Pool, AppThunk } from 'state/types'
import { fetchPoolsTotalStaking, fetchTubeFarmInfo } from './fetchPool'
import {
  fetchPoolsAllowance,
  fetchUserBalances,
  fetchUserStakeBalances,
  fetchUserPendingRewards,
  fetchIsUserJackpotJoined
} from './fetchPoolUser'
import { updateVersion } from '../global/actions'

const initialState: PoolsState = {
  data: [...poolsConfig],
  userDataLoaded: false,
}

export const fetchPoolsPublicDataAsync = (currentBlock: number) => async (dispatch) => {
  const totalStakings = await fetchPoolsTotalStaking()
  const tubeFarms = await fetchTubeFarmInfo()

  const liveData = poolsConfig.map((pool) => {
    const totalStaking = totalStakings.find((entry) => entry.pId === pool.pId)
    const tubeFarm = tubeFarms.find((entry) => entry.pId === pool.pId)

    return {
      ...totalStaking,
      ...tubeFarm,
    }
  })

  dispatch(setPoolsPublicData(liveData))
}

export const fetchPoolsUserDataAsync = (account: string): AppThunk => async (dispatch) => {
  const allowances = await fetchPoolsAllowance(account)
  const stakingTokenBalances = await fetchUserBalances(account)
  const stakedBalances = await fetchUserStakeBalances(account)
  const pendingRewards = await fetchUserPendingRewards(account)
  const isJackpot = await fetchIsUserJackpotJoined(account)

  const userData = poolsConfig.map((pool) => ({
    pId: pool.pId,
    allowance: allowances[pool.pId],
    stakingTokenBalance: stakingTokenBalances[pool.pId],
    stakedBalance: stakedBalances[pool.pId],
    pendingReward: pendingRewards[pool.pId],
    isJackpotJoined: isJackpot[pool.pId]
  }))

  dispatch(setPoolsUserData(userData))
}

export const updateUserAllowance = (pId: number, account: string): AppThunk => async (dispatch) => {
  const allowances = await fetchPoolsAllowance(account)
  dispatch(updatePoolsUserData({ pId, field: 'allowance', value: allowances[pId] }))
}

export const updateUserBalance = (pId: number, account: string): AppThunk => async (dispatch) => {
  const tokenBalances = await fetchUserBalances(account)
  dispatch(updatePoolsUserData({ pId, field: 'stakingTokenBalance', value: tokenBalances[pId] }))
}

export const updateUserStakedBalance = (pId: number, account: string): AppThunk => async (dispatch) => {
  const stakedBalances = await fetchUserStakeBalances(account)
  dispatch(updatePoolsUserData({ pId, field: 'stakedBalance', value: stakedBalances[pId] }))
}

export const updateUserPendingReward = (pId: number, account: string): AppThunk => async (dispatch) => {
  const pendingRewards = await fetchUserPendingRewards(account)
  dispatch(updatePoolsUserData({ pId, field: 'stakedBalance', value: pendingRewards[pId] }))
}

export const updateIsUserJackpotJoined = (pId: number, account: string): AppThunk => async (dispatch) => {
  const isJackpot = await fetchIsUserJackpotJoined(account)
  dispatch(updatePoolsUserData({ pId, field: 'isJackpotJoined', value: isJackpot[pId] }))
}

export const PoolsSlice = createSlice({
  name: 'Pools',
  initialState,
  reducers: {
    setPoolsPublicData: (state, action) => {
      const livePoolsData: Pool[] = action.payload
      state.data = state.data.map((pool) => {
        const livePoolData = livePoolsData.find((entry) => entry.pId === pool.pId)
        return { ...pool, ...livePoolData }
      })
    },
    setPoolsUserData: (state, action) => {
      const userData = action.payload
      state.data = state.data.map((pool) => {
        const userPoolData = userData.find((entry) => entry.pId === pool.pId)
        return { ...pool, userData: userPoolData }
      })
      state.userDataLoaded = true
    },
    updatePoolsUserData: (state, action) => {
      const { field, value, pId } = action.payload
      const index = state.data.findIndex((p) => p.pId === pId)

      if (index >= 0) {
        state.data[index] = { ...state.data[index], userData: { ...state.data[index].userData, [field]: value } }
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateVersion, (state) => {
      // reset state to init value
      state.data = initialState.data
      state.userDataLoaded = initialState.userDataLoaded
    })
  }
})

// Actions
export const { setPoolsPublicData, setPoolsUserData, updatePoolsUserData } = PoolsSlice.actions

export default PoolsSlice.reducer
