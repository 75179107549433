import React from 'react'
import styled from 'styled-components'
import { Button, useWalletModal } from '@pancakeswap/uikit'
import { useTranslation } from 'contexts/Localization'
import useAuth from 'hooks/useAuth'

const MAccountWalletButton = styled(Button)`
  width: 210px;
  height: 40px;
  line-height: 20px;
  border: 2px solid #ebebeb;
  border-radius: 20px;
  text-transform: uppercase;
  color: white;
  font-size: 16px;
  padding-left: 28px;
  white-space: nowrap;
  background-color: transparent;

  i {
    font-size: 18px;
    margin-left: 8px;
    vertical-align: middle;
  }
`

const ConnectWalletButton = (props) => {
  const { t } = useTranslation()
  const { login, logout } = useAuth()
  const { account } = props
  const { onPresentAccountModal } = useWalletModal(login, logout, account, {
    copyText: t("Copy Address"),
    logoutText: t("Logout"),
    linkText: t("View on Etherscan"),
    copiedText: t("Copied")
  })
  const address = account ? `${account.substring(0, 6)}...${account.substring(account.length - 6)}` : null

  return (
    <MAccountWalletButton onClick={onPresentAccountModal} {...props}>
      {address} <i className="flaticon-right-thin" />
    </MAccountWalletButton>
  )
}

export default ConnectWalletButton
