import { ChainId } from '@pancakeswap/sdk'
import addresses from 'config/constants/contracts'
import tokens from 'config/constants/tokens'
import { Address } from 'config/constants/types'

export const getAddress = (address: Address): string => {
  const chainId = process.env.REACT_APP_CHAIN_ID
  return address[chainId] ? address[chainId] : address[ChainId.MAINNET]
}

export const getStakingAddress = () => {
  return getAddress(addresses.stakingContr)
}

export const getJackpotAddress = () => {
  return getAddress(addresses.jackpotContr)
}

export const getJackpotAddressV2 = () => {
  return getAddress(addresses.jackpotContrV2)
}

export const getTubeFarmAddress = () => {
  return getAddress(addresses.tubeFarmContr)
}

export const getTreasuryAddress = () => {
  return getAddress(addresses.treasuryContr)
}

export const getCakeAddress = () => {
  return getAddress(tokens.cake.address)
}

export const getMulticallAddress = () => {
  return getAddress(addresses.multiCall)
}
