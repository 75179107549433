import React from 'react'
import styled from 'styled-components'
import { Button, useWalletModal } from '@pancakeswap/uikit'
import useAuth from 'hooks/useAuth'
import { useTranslation } from 'contexts/Localization'
import { languageList } from 'config/localization/languages'

const MConnectWalletButton = styled(Button)`
  width: 210px;
  height: 40px;
  line-height: 20px;
  border: 2px solid #ebebeb;
  border-radius: 20px;
  text-transform: uppercase;
  color: white;
  font-size: 16px;
  padding-left: 28px;
  white-space: nowrap;
  background-color: transparent;

  i {
    font-size: 18px;
    margin-left: 8px;
    vertical-align: middle;
  }
`

const ConnectWalletButton = (props) => {
  const { t, currentLanguage, setLanguage } = useTranslation()
  const { login, logout } = useAuth()
  const { onPresentConnectModal } = useWalletModal(login, logout, undefined, {})

  return (
    <MConnectWalletButton onClick={onPresentConnectModal} {...props}>
      {t('Connect Wallet')} <i className="flaticon-right-thin" />
    </MConnectWalletButton>
  )
}

export default ConnectWalletButton
