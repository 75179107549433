import React from 'react'
import { ETH_SCAN_URLS } from 'config'
import { getStakingAddress, getJackpotAddressV2 } from 'utils/addressHelpers'
import { useTranslation } from 'contexts/Localization'

const Footer = () => {
  const { t } = useTranslation()
  const chainId = process.env.REACT_APP_CHAIN_ID
  const url = `${ETH_SCAN_URLS[chainId]}address/${getStakingAddress()}`
  const url2 = `${ETH_SCAN_URLS[chainId]}address/${getJackpotAddressV2()}`

  return (
    <footer className="theme-footer dark-style">
      <div className="container">
        <div className="inner-wrapper">
          <div className="top-footer-data-wrapper">
            <div className="row">
              <div className="col-lg-4 col-sm-6 footer-logo">
                <div className="logo"><a href="/"><img src="/images/logo2.png?v=2" alt="Logo" width="150" height="38" /></a></div>
              </div>
              <div className="col-lg-4 col-sm-6 footer-list">
                <h4 className="title">{t('Quick Links')}</h4>
                <ul>
                  <li><a href={url} target="_blank" rel="noreferrer">{t('Farm Contract')}</a></li>
                  <li><a href={url2} target="_blank" rel="noreferrer">{t('Aggregator Contract')}</a></li>
                  <li><a href="/farm">{t('Farm')}</a></li>
                  <li><a href="/aggregator">{t('Aggregator')}</a></li>
                </ul>
              </div>
              <div className="col-lg-4 col-sm-6 footer-list">
                <h4 className="title">{t('Join')}</h4>
                <ul>
                  <li><a href={process.env.REACT_APP_ESWAP_LINK} target="_blank" rel="noreferrer">{t('Eswap')}</a></li>
                </ul>
              </div>
            </div>
          </div>

          <div className="bottom-footer clearfix">
            <p className="copyright">&copy; 2021 <a href="/">Debut</a> All Right Reserved</p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer
