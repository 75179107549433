import tokens from './tokens'
import { PoolConfig } from './types'

const pool: PoolConfig[] = [
  {
    pId: 0,
    pLabel: 'TUBE2 / ETUBE1 LP',
    earningToken: tokens.tube2,
    stakingToken: tokens.etube1tube2,
    contractAddress: {
      1: '0x31E329B6c9Eabe77ddB08C61a3c0A95e8c1137aA',
      3: '',
      4: '0x524a04Bcd4870aD85F2bd271c8695032E197b355'
    }, // below part is calculate apr
    isLP: true,
    farmId: 2,
    quoteAddress: { // for pair contract
      1: '0xaBd894D40B7E33A8eA28126509DC9234e6bB4683',
      3: '',
      4: '0x5AABa53f87D3471A75078AaeEfB912DA036A510a'
    }
  }
]

export default pool
